// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/BlogIndex.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/ContactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/HomePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-project-index-js": () => import("./../../../src/templates/ProjectIndex.js" /* webpackChunkName: "component---src-templates-project-index-js" */),
  "component---src-templates-project-post-js": () => import("./../../../src/templates/ProjectPost.js" /* webpackChunkName: "component---src-templates-project-post-js" */),
  "component---src-templates-service-page-js": () => import("./../../../src/templates/ServicePage.js" /* webpackChunkName: "component---src-templates-service-page-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/SinglePost.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-templates-workshop-kids-js": () => import("./../../../src/templates/WorkshopKids.js" /* webpackChunkName: "component---src-templates-workshop-kids-js" */)
}

